import { Link as RemixLink, type LinkProps as RemixLinkProps } from "@remix-run/react";

import { cx } from "~/design/generated/css";
import { type LinkVariantProps, link } from "~/design/generated/recipes";

export type LinkProps = RemixLinkProps &
  LinkVariantProps & {
    className?: string;
  };

export function Link({ className, ...props }: LinkProps) {
  return <RemixLink {...props} className={cx(link(props), className)} />;
}
