import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const linkFn = /* @__PURE__ */ createRecipe('link', {
  "color": "neutral",
  "size": "inherit",
  "decoration": "none"
}, [])

const linkVariantMap = {
  "color": [
    "accent",
    "neutral",
    "neutral.soft",
    "neutral.softest",
    "inherited"
  ],
  "size": [
    "inherit",
    "md",
    "sm"
  ],
  "decoration": [
    "none",
    "underline"
  ]
}

const linkVariantKeys = Object.keys(linkVariantMap)

export const link = /* @__PURE__ */ Object.assign(memo(linkFn.recipeFn), {
  __recipe__: true,
  __name__: 'link',
  __getCompoundVariantCss__: linkFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: linkVariantKeys,
  variantMap: linkVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, linkVariantKeys)
  },
  getVariantProps: linkFn.getVariantProps,
})